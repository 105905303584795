// React
import React from 'react';

// Styles
import './footer.scss';

const Footer = () => {
    return <footer className="footer">This is the footer</footer>;
}

export default Footer
